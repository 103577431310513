import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../layouts/MainLayout"

function Magnathon() {
  return (
    <Layout>
      <div className="banner">Magnathon</div>
      <div className="container my-5">
        <div className="d-flex flex-lg-nowrap flex-wrap-reverse align-items-center my-5">
          <p className="flex-half p-4">
            <span className="fs-3">M</span>agnathon 2022 is a themed hackathon
            event that focus on finding the best solution for problems centered
            on sustainability, energy use, climate change and anything
            technology focused on how we interact with the environment for a
            sustainable future. Hackathon allow the space for people to
            experience the reality of the challenge without committing to it
            long-term, and that may reveal skilled new talent from unexpected
            places.Magnathon '22 is a 24 hr offline event conducted as a part of
            CEV's tech fest Quasso Liberum 10.Lets get ready to bring out
            brainstorming ideas and win exciting cash prizes.
            <br />
          </p>
          <StaticImage
            className="flex-half rounded-xl"
            src="../images/magnathon.png"
            alt=""
          />
        </div>
        <div className="d-flex flex-lg-nowrap flex-wrap-reverse flex-row-reverse align-items-center my-5">
          <p className="flex-half p-4">
            IEEE Student Branch of College of Engineering Vadakara is ecstatic
            to announce the call for volunteers of MAGNATHON - 24 hours offline
            Hackathon taking place in conjunction with the most awaited and
            anticipated event Quasso Liberum 10 ✨
            <br />
            <br />
            Don't pass up the opportunity to take part in this captivating event
            💥
            <br />
            <br />
            <div className="fs-3 fw-bold">
              Become our Volunteer :
              <a href="https://bit.ly/magnathoncall" className="fs-3">
                <button className="btn btn-primary fs-3 my-4 fw-bold">
                  Register
                </button>{" "}
              </a>
              (Exclusively for Cevians)
            </div>
            <br />
            <br />
          </p>
          <StaticImage
            className="flex-half rounded-xl"
            src="../images/mag-reg.jpeg"
            alt=""
          />
        </div>
      </div>
    </Layout>
  )
}
export default Magnathon
